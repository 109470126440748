@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  section,
  article,
  footer {
    @apply px-2 sm:px-4 py-6 sm:py-12;
  }

  /* reused styles */
  .flex-center {
    @apply flex items-center justify-center;
  }
  .img-child {
    @apply w-full h-full object-cover;
  }

  .nav-wrapper,
  .footer-wrapper,
  .section-wrapper {
      @apply max-w-[1200px] mx-auto w-full;
  }

  .logo-text {
    @apply text-xl xs:text-2xl sm:text-3xl font-bold;
  }

  .navbar {
    @apply px-2 py-3 border-b border-green-400 capitalize z-[50] fixed top-0 w-full;
  }
  .nav-link {
    @apply duration-500 hover:text-[#ff6127] border-b border-transparent hover:border-[#ff6127] focus-visible:text-[#ff6127] ;
  }

  .mobile-nav-menu {
    @apply fixed z-[60] inset-0;
  }
  .mobile-nav-link {
    @apply duration-500 text-2xl capitalize hover:text-[#f7ceb8];
  }

  .truncate {
    @apply overflow-hidden whitespace-nowrap overflow-ellipsis;
  }
  .big-text-2 {
    @apply text-2xl xs:text-3xl sm:text-4xl font-bold;
  }
  .massive-text-resp {
    @apply text-4xl xs:text-6xl sm:text-7xl md:text-8xl font-bold;
  }
  .massive-text-resp-semi {
    @apply text-4xl xs:text-6xl sm:text-7xl md:text-8xl font-semibold;
  }
  .massive-text-resp-light {
    @apply text-4xl xs:text-6xl sm:text-7xl md:text-8xl font-light;
  } 
  .massive-text-resp-thin {
    @apply text-4xl xs:text-6xl sm:text-7xl md:text-8xl font-thin;
  }
  .big-text-resp {
    @apply text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-bold;
  }
  .big-text-resp-light {
    @apply text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-light;
  }
  .big-text-resp-2 {
    @apply text-3xl xs:text-4xl sm:text-5xl md:text-6xl font-light;
  }
  .big-text-resp-3 {
    @apply text-2xl xs:text-3xl sm:text-4xl md:text-5xl font-semibold;
  }
  .big-text-resp-4 {
    @apply text-xl xs:text-2xl sm:text-3xl md:text-4xl font-semibold;
  }
  .big-text-resp-5 {
    @apply text-lg xs:text-xl sm:text-2xl md:text-3xl font-light;
  }
  .normal-text-resp {
    @apply text-base xs:text-lg sm:text-xl md:text-2xl font-light;
  }
  .normal-text-resp-2 {
    @apply text-sm xs:text-base sm:text-lg md:text-xl font-light;
  }
  .normal-text-resp-3 {
    @apply text-xs xs:text-sm sm:text-base md:text-lg font-light;
  }
  .normal-text-resp-para {
    @apply text-xs xs:text-sm sm:text-base font-normal;
  }
  .small-text {
    @apply text-xs xs:text-sm;
  }
  .xs-text {
    @apply text-[9px] sm:text-xs md:text-sm font-light;
  }
  .short-card-text * {
    @apply !text-xs;
  }

  .mb-resp {
    @apply mb-2 xs:mb-4 sm:mb-6 md:mb-8;
  }
  .mb-resp-2 {
    @apply mb-4 xs:mb-6 sm:mb-8;
  }
  .p-resp {
    @apply p-2 xs:p-4 sm:p-6 md:p-8;
  }

  .scroll-top-button {
    @apply shadow w-[3rem] h-[3rem] rounded-full;
  }

  .footer-heading {
    @apply text-lg xs:text-xl sm:text-2xl font-semibold uppercase;
  }
  .footer-head {
    @apply mb-2 border-b-2 border-white p-2;
  }
  .footer-links-list {
    @apply flex flex-col gap-2;
  }
  .footer-link {
    @apply hover:text-[#ec9987] focus-visible:text-[#ec9987] duration-300 capitalize;
  }
  .footer-wrapper {
    @apply flex flex-col sm:flex-row sm:justify-between gap-4;
  }
  .footer-link-container {
    @apply flex-1;
  }
  .footer-social-list {
    @apply flex items-center justify-center gap-4 py-4;
  }
  .footer-social-link {
    @apply py-2 px-4 shadow rounded-lg block duration-300 hover:-translate-y-2 focus-visible:-translate-y-1 text-xl;
  }
  /* end reused styles */

  /* =======custom styles */
  p {
    @apply normal-text-resp-para;
  }

  .destination-desc img {
    @apply my-2 sm:my-4 max-h-[28.5rem] object-cover block w-full rounded-2xl;
  }
  .destination-desc h1,
  .destination-desc h2,
  .destination-desc h3,
  .destination-desc h4,
  .destination-desc h5,
  .destination-desc h6 {
    @apply mb-2 xs:mb-4 sm:mb-6 capitalize normal-text-resp-2 text-[#05a043];
  }
  .destination-desc strong,
  .destination-desc b {
    @apply font-bold text-[#05a043];
  }
  .destination-desc i {
    @apply font-extralight;
  }
  /* end custom styles */
}