/* 
    Raleway, Maitree and Overpass fonts are imported from Google Fonts.
*/
@import url('https://fonts.googleapis.com/css2?family=Julee&family=Lora:ital,wght@0,400..700;1,400..700&family=Maven+Pro:wght@400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  /* fonts */
  --primary-font: "Lora", serif;
  --secondary-font: "Roboto", sans-serif;
  --tertiary-font: "Maven Pro", sans-serif;
  --cursive-font: "Julee", cursive;

  /* colors */
  --primary-color: #05a043;
  --primary-color-opaque: rgba(13, 13, 13, .6);
  --secondary-color: #fff;
  --accent-color: #ff6127;
  --accent-color-bright: #f9784a;
  --primary-50: #68f09e;
  --primary-100: #1ec15f;
  --primary-200: #0cb851;
  --primary-300: #0f833d;

  /* box-shadow */
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow-md: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

}

/* reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: clip;
}

body {
  font-family: var(--tertiary-font);
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  overflow-x: clip;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

input,
textarea {
  resize: none;
  outline: none;
}

img {
  max-width: 100%;
  display: block;
}

/* typography */
h1, h2, h3 {
  font-family: var(--primary-font);
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

h4, h5, h6 {
  font-family: var(--secondary-font);
}

p {
  font-family: var(--tertiary-font);
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

.logo {
  font-family: var(--cursive-font);
}

/* utilities */
.truncate-2,
.truncate-3,
.truncate-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.truncate-2 {
  -webkit-line-clamp: 2;
}
.truncate-3 {
  -webkit-line-clamp: 3;
}
.truncate-4 {
  -webkit-line-clamp: 4;
}

/* colors utilities */
.bg-primary {
  background-color: var(--primary-color);
}
.bg-primary-50 {
  background-color: var(--primary-50);
}
.bg-primary-100 {
  background-color: var(--primary-100);
}
.bg-primary-200 {
  background-color: var(--primary-200);
}
.bg-primary-300 {
  background-color: var(--primary-300);
}
.bg-secondary {
  background-color: var(--secondary-color);
}
.bg-accent {
  background-color: var(--accent-color);
}
.bg-accent-bright {
  background-color: var(--accent-color-bright);
}

/* text */
.text-primary {
  color: var(--primary-color);
}
.text-primary-50 {
  color: var(--primary-50);
}
.text-primary-100 {
  color: var(--primary-100);
}
.text-primary-200 {
  color: var(--primary-200);
}
.text-primary-300 {
  color: var(--primary-300);
}
.text-secondary {
  color: var(--secondary-color);
}
.text-accent {
  color: var(--accent-color);
}
.text-accent-bright {
  color: var(--accent-color-bright);
}

/* ============custom styles */
/* nav */
.navbar-custom {
  transform: translateY(-200%);
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}
.navbar-custom.active {
  visibility: visible;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
}

.mobile-nav-menu {
  transform: translateX(200%);
  -webkit-transform: translateX(200%);
  -moz-transform: translateX(200%);
  -ms-transform: translateX(200%);
  -o-transform: translateX(200%);
  visibility: hidden;
  transition: all 0.5s cubic-bezier( 0.21, 0.18, 0.07, 0.95 );
  -webkit-transition: all 0.5s cubic-bezier( 0.21, 0.18, 0.07, 0.95 );
  -moz-transition: all 0.5s cubic-bezier( 0.21, 0.18, 0.07, 0.95 );
  -ms-transition: all 0.5s cubic-bezier( 0.21, 0.18, 0.07, 0.95 );
  -o-transition: all 0.5s cubic-bezier( 0.21, 0.18, 0.07, 0.95 );
}
.mobile-nav-menu.active {
  visibility: visible;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
}

.hide-toast {
  opacity: 0;
  visibility: hidden;
}

/* ================= fancy grid */
.fancy-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  padding: 20px;
}

.grid-item {
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.grid-item:hover {
  transform: scale(1.05);
}

/* Style variations for large, tall, and small items */
.grid-item.large {
  grid-column: span 2;
  grid-row: span 2;
  height: 300px;
}

.grid-item.tall {
  grid-column: span 1;
  grid-row: span 2;
  height: 250px;
}

.grid-item.small {
  grid-column: span 1;
  grid-row: span 1;
  height: 150px;
}

/* Image styling */
.fancy-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 8px;
}

/* Responsive adjustments for larger screens */
@media (min-width: 600px) {
  .fancy-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

/* auth */
.auth-bg-container {
  background: url("https://images.unsplash.com/photo-1727023663921-967d01f69c7e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") no-repeat center center;
  background-size: cover;
  background-color: rgba(0,255,0,0.4);
  background-blend-mode: multiply;
}

/* ===========animations */
.fade-in {
  animation: fadeIn 0.8s ease-in-out forwards;
  -webkit-animation: fadeIn 0.8s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
